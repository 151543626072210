<template>
  <div class="ud-body">
    <a-card :bordered="true">
      <a-form
        ref="form"
        :rules="rules"
        :label-col="labelCol"
        :model="form"
        :wrapper-col="wrapperCol"
        class="form"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="订单编号" name="orderCode">
              <a-input
                placeholder="请输入订单编号"
                v-model:value="form.orderCode"
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="业务类型" name="businessType">
              <a-select
                v-model:value="form.businessType"
                placeholder="请选择业务类型"
              >
                <a-select-option value="0">换电下单</a-select-option>
                <a-select-option value="0">续租</a-select-option>
                <a-select-option value="0">退租</a-select-option>
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属运营商" name="operator">
              <a-select
                v-model:value="form.operator"
                placeholder="请选择所属运营商"
              >
                <a-select-option value="0"
                  >厦门远丞科技有限公司</a-select-option
                >
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属租赁点" name="point">
              <a-select
                v-model:value="form.point"
                placeholder="请选择所属租赁点"
              >
                <a-select-option value="0">福建厦门三行网点</a-select-option>
              </a-select>
            </a-form-item></a-col
          >
        </a-row>
        <a-row v-if="expand">
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="品牌" name="brand">
              <a-select v-model:value="form.brand" placeholder="请选择品牌">
                <a-select-option value="0">盾创</a-select-option>
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="型号" name="model">
              <a-select v-model:value="form.model" placeholder="请选择型号">
                <a-select-option value="0">A</a-select-option>
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="用户姓名" name="name">
              <a-input
                placeholder="请输入用户姓名"
                v-model:value="form.name"
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="手机号" name="phoneNumber">
              <a-input
                placeholder="请输入手机号"
                v-model:value="form.phoneNumber"
              ></a-input> </a-form-item
          ></a-col>
        </a-row>
        <a-row v-if="expand">
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="支付时间" name="payTime">
              <a-range-picker
                format="YYYY-MM-DD"
                :placeholder="['开始日期', '结束日期']"
                @change="onChange"
                style="width: 100%"
                v-model:value="form.payTime"
                @ok="onOk" /></a-form-item
          ></a-col>
        </a-row>
        <a-row type="flex" justify="end">
          <a-space class="btn-wrap">
            <a-button type="primary" @click="reload"
              ><i class="iconfont icon-zaitu"></i>查询</a-button
            >
            <a-button @click="reset">
              <i class="iconfont icon-mimazhongzhi"></i>重置</a-button
            >
            <span
              v-if="!expand"
              @click="expandForm"
              style="color: #1890ff; cursor: pointer"
            >
              展开<i class="iconfont icon-xiangxiajiantou"></i>
            </span>
            <span
              v-else
              @click="expandForm"
              style="color: #1890ff; cursor: pointer"
            >
              收起<i class="iconfont icon-xiangshang_jiantou"></i>
            </span>
          </a-space>
        </a-row>
      </a-form>
    </a-card>
    <a-card :bordered="true" class="table">
      <div>
        <ud-pro-table
          ref="table"
          :rowKey="(record) => record.phoneNumber"
          :columns="columns"
          :datasource="data"
          @change="handleChange"
          :scroll="{ x: 'max-content' }"
        >
          <template #toolbar>
            <a-space style="margin: 0 20px">
              <a-button type="primary">
                <i class="iconfont icon-daochu" style="margin-right: 5px"></i>
                <span>导出</span>
              </a-button>
            </a-space>
          </template>
          <template #orderCode="{ record }">
            <div style="color: #409eff; text-decoration: underline">
              {{ record.orderCode }}
            </div>
          </template>
          <template #batteryCode="{ record }">
            <div style="color: #409eff; text-decoration: underline">
              {{ record.batteryCode }}
            </div>
          </template>
          <template #point="{ record }">
            <div style="color: #409eff; text-decoration: underline">
              {{ record.point }}
            </div>
          </template>
          <template #rent="{ record }">
            <div>
              {{ '+' + record.rent }}
            </div>
          </template>
          <template #amountPay="{ record }">
            <div>
              {{ '+' + record.amountPay }}
            </div>
          </template>
          <template #customTitle>
            <div>
              所属运营商
              <i
                class="iconfont icon-bukejian"
                style="cursor: pointer"
                v-if="!isShowOperator"
                @click="isShowOperator = true"
              ></i>
              <i
                class="iconfont icon-kejian"
                style="cursor: pointer"
                v-else
                @click="isShowOperator = false"
              ></i>
            </div>
          </template>
          <template #operatorCustom="{ record }">
            <div v-if="!isShowOperator">
              {{
                record.operator.substring(0, 1) +
                new Array(record.operator.length).join('*')
              }}
            </div>
            <div v-else>
              {{ record.operator }}
            </div>
          </template>
        </ud-pro-table>
      </div>
    </a-card>

    <!-- <operation-detail
      v-model:visible="showDetail"
      :data="current || {}"
    ></operation-detail>
    <operation-edit v-model:visible="showEdit" :data="current" @done="reload" /> -->
  </div>
</template>

<script>
// import operationDetail from './operation-detail.vue'
// import operationEdit from './operation-edit.vue'
// import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
export default {
  components: {
    // DownOutlined,
    // ExclamationCircleOutlined
    // operationDetail,
    // operationEdit
  },
  data() {
    return {
      visibleLog: false,
      visibleOrder: false,
      visibleEndTime: false,
      visibleRefund: false,
      visibleBatteryCode: false,
      actionForm: { endTime: '' },
      labelCol: { md: { span: 6 }, sm: { span: 24 } },
      wrapperCol: { md: { span: 16 }, sm: { span: 24 } },
      showDetail: false,
      showEdit: false,
      current: null,
      form: {},
      rules: {},
      expand: false,
      isShowOperator: false,
      columns: [
        {
          title: '序号',
          width: 48,
          // align: 'center',
          customRender: ({ text, record, index }) => index + 1
        },
        {
          title: '订单编号',
          // width: 170,
          // align: 'center',
          dataIndex: 'orderCode'
        },
        {
          title: '业务类型',
          // width: 85,
          // align: 'center',
          dataIndex: 'businessType'
        },
        {
          title: '真实姓名',
          // width: 175,
          // align: 'center',
          dataIndex: 'realName'
        },
        {
          title: '手机号',
          // width: 175,
          // align: 'center',
          dataIndex: 'phoneNumber'
        },
        {
          title: '身份证号',
          // width: 175,
          // align: 'center',
          dataIndex: 'IDNumber'
        },
        {
          title: '品牌',
          // width: 175,
          // align: 'center',
          dataIndex: 'brand'
        },
        {
          title: '型号',
          // width: 175,
          // align: 'center',
          dataIndex: 'model'
        },
        {
          title: '租金',
          // width: 175,
          // align: 'center',
          dataIndex: 'rent',
          slots: {
            customRender: 'rent'
          }
        },
        {
          title: '丢失服务保证金',
          // width: 175,
          // align: 'center',
          dataIndex: 'lostService'
        },
        {
          title: '租金优惠券',
          // width: 175,
          // align: 'center',
          dataIndex: 'rentCoupon'
        },
        {
          title: '实付金额',
          // width: 175,
          // align: 'center',
          dataIndex: 'amountPay',
          slots: {
            customRender: 'amountPay'
          }
        },
        {
          title: '资金流水号',
          // width: 175,
          // align: 'center',
          dataIndex: 'cashNumber'
        },
        {
          title: '支付时间',
          // width: 175,
          // align: 'center',
          dataIndex: 'payTime'
        },
        {
          title: '所属城市',
          // width: 175,
          // align: 'center',
          dataIndex: 'city'
        }

        // {
        //   title: '操作',
        //   width: 200,
        //   dataIndex: 'action',
        //   align: 'center',
        //   slots: {
        //     customRender: 'action'
        //   }
        // }
      ],
      data: [
        {
          orderCode: 'Ord21130000315',
          businessType: '换电下单',
          realName: '张俊杰',
          phoneNumber: '18370082684',
          IDNumber: '362427189803150316',
          brand: '顶宇锂电',
          model: '72v55Ah',
          rent: '380',
          lostService: '0.0',
          rentCoupon: '0.0',
          amountPay: '380',
          cashNumber: '4200001366202201016635655108',
          payTime: '2022-01-11 09:45:36',
          city: '厦门市'
        },
        {
          orderCode: 'Ord21130000305',
          businessType: '续租',
          realName: '张俊杰',
          phoneNumber: '18370082684',
          IDNumber: '362427189803150316',
          brand: '顶宇锂电',
          model: '72v55Ah',
          rent: '380',
          lostService: '0.0',
          rentCoupon: '0.0',
          amountPay: '380',
          cashNumber: '4200001366202201016635655108',
          payTime: '2022-01-11 09:45:36',
          city: '厦门市'
        }
      ]
    }
  },
  methods: {
    openDetail(row) {
      this.current = row
      this.showDetail = true
    },
    openEdit(row) {
      console.log(row)
      this.current = row
      this.showEdit = true
    },
    // 新增计费模板确定按钮回调事件
    handleBillingOk() {
      this.$refs.billingForm.validate().then((res) => {
        console.log(res)
        this.$message.success('添加计费模板成功！')
        this.visible = false
      })
    },
    // 是否展开表单
    expandForm() {
      this.expand = !this.expand
    },
    // 表格改变
    handleChange() {}
  }
}
</script>
<style lang='less' scoped>
.form {
  .btn-wrap {
    padding-right: 20px;
    .iconfont {
      margin-right: 5px;
    }
  }
}
.table {
  margin: 10px 0;
}
</style>
